import { INITIAL_AUTH_STATE } from "@energylab/shared-components/redux/reducers/authReducer";
import { INITIAL_CONFIG_STATE } from "@energylab/shared-components/redux/reducers/configReducer";
import { INITIAL_MAIN_STATE } from "@energylab/shared-components/redux/reducers/mainReducer";
import { config } from "../config";

export const createInitialStore = () => {
    return {
        auth: INITIAL_AUTH_STATE,
        config: {
            ...INITIAL_CONFIG_STATE,
            ...config
        },
        main: INITIAL_MAIN_STATE
    };
};
